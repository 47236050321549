@keyframes fadeIn-49fe55a8 {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
.opacity-enter-active[data-v-49fe55a8] {
  animation: fadeIn-49fe55a8 .3s;
}
.opacity-leave-active[data-v-49fe55a8] {
  animation: fadeIn-49fe55a8 .3s reverse;
}
.relative[data-v-49fe55a8] {
  position: relative;
}
.w-full[data-v-49fe55a8] {
  width: 100%;
}
.relative-center[data-v-49fe55a8] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-49fe55a8] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-49fe55a8] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-49fe55a8] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-49fe55a8] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-49fe55a8] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-49fe55a8] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-49fe55a8] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-49fe55a8] {
  justify-content: space-between;
}
.flex[data-v-49fe55a8] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-49fe55a8] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-49fe55a8],
.card-list-no-padding .ci-description[data-v-49fe55a8] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.t-title-18[data-v-49fe55a8] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  line-height: 0.09375rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.t-value-16[data-v-49fe55a8] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #fff;
  line-height: 0.08333333rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
a[data-v-49fe55a8] {
  text-decoration: none;
}
.map-marker[data-v-49fe55a8] {
  position: relative;
}
.map-marker div[data-v-49fe55a8] {
  box-shadow: 0 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
  background-color: #fff;
  transform: translateY(calc(-0.09375rem - 100%)) translateX(-0.02083333rem);
  position: absolute;
}
.map-marker div[data-v-49fe55a8]::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.05208333rem;
  height: 0.05208333rem;
  bottom: 0;
  background-color: #fff;
  transform: rotate(45deg) translateY(0.04166667rem);
  box-shadow: 0.015625rem 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
}
.sale-ani[data-v-49fe55a8] {
  animation-name: SaleService-49fe55a8;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes SaleService-49fe55a8 {
0% {
    transform: scaleX(1);
}
10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
}
30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
}
40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
}
to {
    transform: scaleX(1);
}
}
.heart-ani[data-v-49fe55a8] {
  animation-name: Heartbeat-49fe55a8;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes Heartbeat-49fe55a8 {
0% {
    transform: scale(1);
}
14% {
    transform: scale(1.3);
}
28% {
    transform: scale(1);
}
42% {
    transform: scale(1.3);
}
70% {
    transform: scale(1);
}
}
.hover-scale .hover-holder[data-v-49fe55a8] {
  overflow: hidden;
}
.hover-scale .hover-target[data-v-49fe55a8] {
  transform: scale(1);
  transition: all .4s;
}
.hover-scale:hover .hover-target[data-v-49fe55a8] {
  transform: scale(1.2);
}
.get-plan-toast-shadow[data-v-49fe55a8] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.international-service .prod-display[data-v-49fe55a8] {
  padding: 0.41666667rem 0.72916667rem 0;
}
.international-service .prod-display .prod-tabs[data-v-49fe55a8] {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.international-service .prod-display .prod-tabs .prod-item[data-v-49fe55a8] {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.125rem;
  color: #666;
  cursor: pointer;
}
.international-service .prod-display .prod-tabs .prod-item .prod-icon[data-v-49fe55a8] {
  height: 0.16666667rem;
  margin-right: 0.08333333rem;
}
.international-service .prod-display .prod-tabs .prod-item .prod-txt[data-v-49fe55a8] {
  padding: 0 0.02604167rem;
  box-sizing: border-box;
}
.international-service .prod-display .prod-tabs .prod-item[data-v-49fe55a8]:hover {
  color: #096dd9;
}
.international-service .prod-display .prod-tabs .prod-active[data-v-49fe55a8] {
  color: #096dd9;
  flex-wrap: wrap;
}
.international-service .prod-display .prod-tabs .prod-active .prod-icon[data-v-49fe55a8] {
  height: 0.41666667rem;
  margin-right: 0;
}
.international-service .prod-display .prod-tabs .prod-active .prod-txt[data-v-49fe55a8] {
  width: 100%;
  margin-top: 0.08333333rem;
}
.international-service .prod-display .prod-list[data-v-49fe55a8] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.41666667rem;
}
.international-service .prod-display .prod-list .prod-item[data-v-49fe55a8] {
  width: 33%;
  box-sizing: border-box;
  padding: 0.10416667rem;
}
.international-service .prod-display .prod-list .prod-item .prod-inner[data-v-49fe55a8] {
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 0.05208333rem 0 #eee;
}
.international-service .prod-display .prod-list .prod-item .prod-inner .prod-img[data-v-49fe55a8] {
  width: 100%;
  height: 2.29166667rem;
}
.international-service .prod-display .prod-list .prod-item .prod-inner .prod-hover[data-v-49fe55a8] {
  position: absolute;
  border-radius: 0.04166667rem;
  width: calc(100% - 0.16666667rem);
  left: 0.08333333rem;
  bottom: 0.08333333rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  transition: all .5s;
  font-size: 0.09375rem;
  color: #666;
}
.international-service .prod-display .prod-list .prod-item .prod-inner .prod-hover .prod-innerTxt[data-v-49fe55a8] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.international-service .prod-display .prod-list .prod-item .prod-inner:hover .prod-hover[data-v-49fe55a8] {
  opacity: .8;
  height: 40%;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  color: #fff;
  font-size: 0.10416667rem;
}
.international-service .prod-display .prod-list .prod-item .prod-inner:hover .prod-hover .prod-innerTxt[data-v-49fe55a8] {
  white-space: normal;
}
.international-service .product[data-v-49fe55a8] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.international-service .prod-button[data-v-49fe55a8] {
  font-size: 0.125rem;
  color: #fffdfd;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.10416667rem 0;
  margin: 0 auto;
  margin-top: 0.20833333rem;
  width: 1.13020833rem;
  cursor: pointer;
  gap: 0.0625rem;
}
.international-service .prod-button .icon[data-v-49fe55a8] {
  width: 0.15625rem;
}