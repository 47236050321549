.hover-menu {
  border-radius: 0.04166667rem 0 0 0.04166667rem;
  position: fixed;
  right: 0.20833333rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
}
.hover-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0.41666667rem;
  height: 0.41666667rem;
  color: white;
  cursor: pointer;
  position: relative;
  background: white;
  border-radius: 50%;
  color: rgba(78, 153, 253, 1);
  margin-bottom: 0.08333333rem;
  box-shadow: 0 0.02604167rem 0.02604167rem -0.015625rem rgba(0, 0, 0, 0.1), 0 0.04166667rem 0.05208333rem 0.00520833rem rgba(0, 0, 0, 0.06), 0 0.015625rem 0.07291667rem 0.01041667rem rgba(0, 0, 0, 0.05);
}
.hover-menu-item:hover {
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  color: white;
}
.hover-menu-item svg {
  width: 0.125rem;
}
.hover-menu-txt {
  font-size: 0.0625rem;
  margin-top: 0.04166667rem;
}
.hover-menu-wechat:hover .hover-menu-wechat-hide {
  display: block;
}
.hover-menu-wechat-hide {
  display: none;
  position: absolute;
  width: 200%;
  right: 100%;
  top: 0;
  box-shadow: 0 0 0.05208333rem 0.01041667rem #ccc;
}
.hover-menu-wechat-qr {
  width: 100%;
}
.hover-menu-phone:hover .hover-menu-phone-hide {
  display: flex;
}
.hover-menu-phone-hide {
  background: white;
  display: none;
  font-size: 0.1875rem;
  color: #3295f0;
  position: absolute;
  right: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0 0 0.05208333rem 0.01041667rem #ccc;
  white-space: nowrap;
  align-items: center;
  padding: 0 0.10416667rem;
}