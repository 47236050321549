@keyframes fadeIn-0becfd47 {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
.opacity-enter-active[data-v-0becfd47] {
  animation: fadeIn-0becfd47 .3s;
}
.opacity-leave-active[data-v-0becfd47] {
  animation: fadeIn-0becfd47 .3s reverse;
}
.relative[data-v-0becfd47] {
  position: relative;
}
.w-full[data-v-0becfd47] {
  width: 100%;
}
.relative-center[data-v-0becfd47] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-0becfd47] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-0becfd47] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-0becfd47] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-0becfd47] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-0becfd47] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-0becfd47] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-0becfd47] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-0becfd47] {
  justify-content: space-between;
}
.flex[data-v-0becfd47] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-0becfd47] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-0becfd47],
.card-list-no-padding .ci-description[data-v-0becfd47] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.t-title-18[data-v-0becfd47] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  line-height: 0.09375rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.t-value-16[data-v-0becfd47] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #fff;
  line-height: 0.08333333rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
a[data-v-0becfd47] {
  text-decoration: none;
}
.map-marker[data-v-0becfd47] {
  position: relative;
}
.map-marker div[data-v-0becfd47] {
  box-shadow: 0 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
  background-color: #fff;
  transform: translateY(calc(-0.09375rem - 100%)) translateX(-0.02083333rem);
  position: absolute;
}
.map-marker div[data-v-0becfd47]::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.05208333rem;
  height: 0.05208333rem;
  bottom: 0;
  background-color: #fff;
  transform: rotate(45deg) translateY(0.04166667rem);
  box-shadow: 0.015625rem 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
}
.sale-ani[data-v-0becfd47] {
  animation-name: SaleService-0becfd47;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes SaleService-0becfd47 {
0% {
    transform: scaleX(1);
}
10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
}
30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
}
40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
}
to {
    transform: scaleX(1);
}
}
.heart-ani[data-v-0becfd47] {
  animation-name: Heartbeat-0becfd47;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes Heartbeat-0becfd47 {
0% {
    transform: scale(1);
}
14% {
    transform: scale(1.3);
}
28% {
    transform: scale(1);
}
42% {
    transform: scale(1.3);
}
70% {
    transform: scale(1);
}
}
.hover-scale .hover-holder[data-v-0becfd47] {
  overflow: hidden;
}
.hover-scale .hover-target[data-v-0becfd47] {
  transform: scale(1);
  transition: all .4s;
}
.hover-scale:hover .hover-target[data-v-0becfd47] {
  transform: scale(1.2);
}
.get-plan-toast-shadow[data-v-0becfd47] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.custom-select[data-v-0becfd47] {
  position: relative;
  width: 1.16666667rem;
}
.selected-option[data-v-0becfd47] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.05208333rem 0.0625rem;
  border: 0.00520833rem solid #ddd;
  border-radius: 0.02083333rem;
  cursor: pointer;
  transition: all .2s;
  height: 0.26041667rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  box-sizing: border-box;
}
.isPlaceHolder[data-v-0becfd47] {
  color: #999;
}
.selected-option[data-v-0becfd47]:hover {
  background-color: #f5f5f5;
}
.arrow[data-v-0becfd47] {
  border: solid #999;
  border-width: 0 0.01041667rem 0.01041667rem 0;
  display: inline-block;
  padding: 0.015625rem;
  transform: rotate(45deg);
}
.options[data-v-0becfd47] {
  position: absolute;
  top: calc(100% + 0.04166667rem);
  left: 0;
  width: 100%;
  max-height: 1.04166667rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 999;
  display: flex;
}
.options[data-v-0becfd47]::before {
  content: "";
  display: block;
  width: 0.05208333rem;
  height: 0.05208333rem;
  background-color: #fff;
  position: absolute;
  left: 0.078125rem;
  top: 0;
  transform: rotate(45deg) translateY(-50%);
}
.scroll-holder[data-v-0becfd47] {
  box-shadow: 0.01041667rem 0.01041667rem 0.02604167rem #999;
  background-color: #fff;
  border-radius: 0.02083333rem;
  height: 100%;
  overflow: hidden;
  max-height: 1.04166667rem;
  flex: 1;
}
.scroll-content[data-v-0becfd47] {
  overflow-y: auto;
  max-height: 1.04166667rem;
  height: 100%;
  padding: 0.02604167rem 0;
}
.options .o-li[data-v-0becfd47] {
  padding: 0.05208333rem;
  cursor: pointer;
  transition: all .2s;
  z-index: 1;
  position: relative;
}
.options .o-li[data-v-0becfd47]:hover {
  background-color: #f2f2f2;
  color: #4e99fd;
}