/* layer: preflights */

*,
::before,
::after {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0;
  --un-ring-color: rgb(147 197 253 / 0.5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}

::backdrop {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0;
  --un-ring-color: rgb(147 197 253 / 0.5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}

/* layer: shortcuts */

.absolute-bl,
.absolute-lb {
  position: absolute;
  left: 0;
  bottom: 0;
}

.absolute-br,
.absolute-rb {
  position: absolute;
  right: 0;
  bottom: 0;
}

.absolute-center {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.absolute-lt,
.absolute-tl {
  position: absolute;
  left: 0;
  top: 0;
}

.absolute-rt,
.absolute-tr {
  position: absolute;
  right: 0;
  top: 0;
}

.fixed-bl,
.fixed-lb {
  position: fixed;
  left: 0;
  bottom: 0;
}

.fixed-br,
.fixed-rb {
  position: fixed;
  right: 0;
  bottom: 0;
}

.fixed-center {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-lt,
.fixed-tl {
  position: fixed;
  left: 0;
  top: 0;
}

.fixed-rt,
.fixed-tr {
  position: fixed;
  right: 0;
  top: 0;
}

.wh-full {
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-col-stretch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.flex-x-center {
  display: flex;
  justify-content: center;
}

.flex-y-center {
  display: flex;
  align-items: center;
}

.i-flex-col {
  display: flex;
  display: inline-flex;
  flex-direction: column;
}

.i-flex-col-stretch {
  display: flex;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}

.i-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.i-flex-x-center {
  display: inline-flex;
  justify-content: center;
}

.i-flex-y-center {
  display: inline-flex;
  align-items: center;
}

.flex-1-hidden {
  flex: 1 1 0%;
  overflow: hidden;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nowrap-hidden {
  overflow: hidden;
  white-space: nowrap;
}

.transition-base {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

/* layer: default */

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.static {
  position: static;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.z-0 {
  z-index: 0;
}

.grid {
  display: grid;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-24px {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.mb-40px {
  margin-bottom: 0.20833333rem;
}

.mb-60px {
  margin-bottom: 0.3125rem;
}

.ml-16px {
  margin-left: 0.08333333rem;
}

.ml-4px {
  margin-left: 0.02083333rem;
}

.ml-5px {
  margin-left: 0.02604167rem;
}

.ml-8px {
  margin-left: 0.04166667rem;
}

.mr-5px {
  margin-right: 0.02604167rem;
}

.mt-10px {
  margin-top: 0.05208333rem;
}

.mt-12px {
  margin-top: 0.0625rem;
}

.mt-16px {
  margin-top: 0.08333333rem;
}

.mt-17px {
  margin-top: 0.08854167rem;
}

.mt-18px {
  margin-top: 0.09375rem;
}

.mt-22px {
  margin-top: 0.11458333rem;
}

.mt-24px {
  margin-top: 0.125rem;
}

.mt-30px {
  margin-top: 0.15625rem;
}

.mt-32px {
  margin-top: 0.16666667rem;
}

.mt-36px {
  margin-top: 0.1875rem;
}

.mt-40px {
  margin-top: 0.20833333rem;
}

.mt-48px {
  margin-top: 0.25rem;
}

.mt-60px {
  margin-top: 0.3125rem;
}

.mt-66px\! {
  margin-top: 0.34375rem !important;
}

.mt-80px {
  margin-top: 0.41666667rem;
}

.mt-8px {
  margin-top: 0.04166667rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.h-146px {
  height: 0.76041667rem;
}

.h-262px {
  height: 1.36458333rem;
}

.h-285px {
  height: 1.484375rem;
}

.h-291px {
  height: 1.515625rem;
}

.h-32px {
  height: 0.16666667rem;
}

.h-372px {
  height: 1.9375rem;
}

.h-377px {
  height: 1.96354167rem;
}

.h-438px {
  height: 2.28125rem;
}

.h-502px {
  height: 2.61458333rem;
}

.h-540px {
  height: 2.8125rem;
}

.h-55px {
  height: 0.28645833rem;
}

.h-580px {
  height: 3.02083333rem;
}

.h-60px {
  height: 0.3125rem;
}

.h-84px {
  height: 0.4375rem;
}

.h-96px\! {
  height: 0.5rem !important;
}

.h-full {
  height: 100%;
}

.h-full\! {
  height: 100% !important;
}

.max-w-573px {
  max-width: 2.984375rem;
}

.w-200px {
  width: 1.04166667rem;
}

.w-302px {
  width: 1.57291667rem;
}

.w-303px {
  width: 1.578125rem;
}

.w-40\% {
  width: 40%;
}

.w-405px {
  width: 2.109375rem;
}

.w-40px {
  width: 0.20833333rem;
}

.w-411px {
  width: 2.140625rem;
}

.w-432px {
  width: 2.25rem;
}

.w-45\% {
  width: 45%;
}

.w-490px {
  width: 2.55208333rem;
}

.w-50px {
  width: 0.26041667rem;
}

.w-54\% {
  width: 54%;
}

.w-60px {
  width: 0.3125rem;
}

.w-88px {
  width: 0.45833333rem;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.transform {
  -webkit-transform: translateX(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotateZ(var(--un-rotate-z)) skewX(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z));
  transform: translateX(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotateZ(var(--un-rotate-z)) skewX(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z));
}

.transform-none\! {
  -webkit-transform: none !important;
  transform: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-10\% {
  gap: 10%;
}

.gap-10px {
  gap: 0.05208333rem;
}

.gap-16px {
  gap: 0.08333333rem;
}

.gap-180px {
  gap: 0.9375rem;
}

.gap-200px {
  gap: 1.04166667rem;
}

.gap-20px {
  gap: 0.10416667rem;
}

.gap-24px {
  gap: 0.125rem;
}

.gap-30px {
  gap: 0.15625rem;
}

.gap-32px {
  gap: 0.16666667rem;
}

.gap-40px {
  gap: 0.20833333rem;
}

.gap-5\% {
  gap: 5%;
}

.gap-60px {
  gap: 0.3125rem;
}

.gap-80px {
  gap: 0.41666667rem;
}

.gap-8px {
  gap: 0.04166667rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.break-all {
  word-break: break-all;
}

.b,
.border {
  border-width: 0.00520833rem;
}

.bg-\#e5eefc {
  --un-bg-opacity: 1;
  background-color: rgb(229 238 252 / var(--un-bg-opacity));
}

.bg-\#f2f7fd {
  --un-bg-opacity: 1;
  background-color: rgb(242 247 253 / var(--un-bg-opacity));
}

.bg-\#fff,
.bg-white {
  --un-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--un-bg-opacity));
}

.bg-amber {
  --un-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--un-bg-opacity));
}

.bg-black {
  --un-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--un-bg-opacity));
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.object-fill {
  -o-object-fit: fill;
  object-fit: fill;
}

.p-22px {
  padding: 0.11458333rem;
}

.p-24px {
  padding: 0.125rem;
}

.p-30px {
  padding: 0.15625rem;
}

.p-40px {
  padding: 0.20833333rem;
}

.p-4px {
  padding: 0.02083333rem;
}

.px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-108px {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}

.px-30px {
  padding-left: 0.15625rem;
  padding-right: 0.15625rem;
}

.px-60px {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.px2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-18px {
  padding-top: 0.09375rem;
  padding-bottom: 0.09375rem;
}

.py-20px {
  padding-top: 0.10416667rem;
  padding-bottom: 0.10416667rem;
}

.py-24px {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-40px {
  padding-top: 0.20833333rem;
  padding-bottom: 0.20833333rem;
}

.py-60px {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.py-80px {
  padding-top: 0.41666667rem;
  padding-bottom: 0.41666667rem;
}

.pb-80px {
  padding-bottom: 0.41666667rem;
}

.pl-19px {
  padding-left: 0.09895833rem;
}

.pl-24px {
  padding-left: 0.125rem;
}

.pl-6px\! {
  padding-left: 0.03125rem !important;
}

.pl-90px {
  padding-left: 0.46875rem;
}

.pl-94px {
  padding-left: 0.48958333rem;
}

.pt-10px\! {
  padding-top: 0.05208333rem !important;
}

.pt-24px {
  padding-top: 0.125rem;
}

.pt-40px {
  padding-top: 0.20833333rem;
}

.pt-60px {
  padding-top: 0.3125rem;
}

.pt-80px {
  padding-top: 0.41666667rem;
}

.text-14px {
  font-size: 0.07291667rem;
}

.text-16px {
  font-size: 0.08333333rem;
}

.text-18px {
  font-size: 0.09375rem;
}

.text-20px {
  font-size: 0.10416667rem;
}

.text-24px {
  font-size: 0.125rem;
}

.text-25px {
  font-size: 0.13020833rem;
}

.text-28px {
  font-size: 0.14583333rem;
}

.text-32px {
  font-size: 0.16666667rem;
}

.text-\#282938 {
  --un-text-opacity: 1;
  color: rgb(40 41 56 / var(--un-text-opacity));
}

.text-\#333 {
  --un-text-opacity: 1;
  color: rgb(51 51 51 / var(--un-text-opacity));
}

.text-\#3384E8 {
  --un-text-opacity: 1;
  color: rgb(51 132 232 / var(--un-text-opacity));
}

.text-\#4E99FDFF {
  --un-text-opacity: 1;
  color: rgb(78 153 253 / var(--un-text-opacity));
}

.text-\#666 {
  --un-text-opacity: 1;
  color: rgb(102 102 102 / var(--un-text-opacity));
}

.text-white {
  --un-text-opacity: 1;
  color: rgb(255 255 255 / var(--un-text-opacity));
}

.hover\:text-\#096DD9:hover {
  --un-text-opacity: 1;
  color: rgb(9 109 217 / var(--un-text-opacity));
}

.font-bold {
  font-weight: 700;
}

.font-bold\! {
  font-weight: 700 !important;
}

.line-height-28px {
  line-height: 0.14583333rem;
}

.line-height-30px\! {
  line-height: 0.15625rem !important;
}

.line-height-32px {
  line-height: 0.16666667rem;
}

.line-height-36px {
  line-height: 0.1875rem;
}

.tab {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

.outline {
  outline-style: solid;
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--un-backdrop-blur) var(--un-backdrop-brightness) var(--un-backdrop-contrast) var(--un-backdrop-grayscale) var(--un-backdrop-hue-rotate) var(--un-backdrop-invert) var(--un-backdrop-opacity) var(--un-backdrop-saturate) var(--un-backdrop-sepia);
  backdrop-filter: var(--un-backdrop-blur) var(--un-backdrop-brightness) var(--un-backdrop-contrast) var(--un-backdrop-grayscale) var(--un-backdrop-hue-rotate) var(--un-backdrop-invert) var(--un-backdrop-opacity) var(--un-backdrop-saturate) var(--un-backdrop-sepia);
}

.transition {
  transition-property: color,background-color,border-color,outline-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.wh-160px {
  width: 0.83333333rem;
  height: 0.83333333rem;
}

.wh-18px {
  width: 0.09375rem;
  height: 0.09375rem;
}

.wh-20px {
  width: 0.10416667rem;
  height: 0.10416667rem;
}

.wh-24px {
  width: 0.125rem;
  height: 0.125rem;
}

.wh-32px {
  width: 0.16666667rem;
  height: 0.16666667rem;
}

.wh-372px {
  width: 1.9375rem;
  height: 1.9375rem;
}

.wh-40px {
  width: 0.20833333rem;
  height: 0.20833333rem;
}

.wh-432px {
  width: 2.25rem;
  height: 2.25rem;
}

.wh-60px {
  width: 0.3125rem;
  height: 0.3125rem;
}

.wh-80px {
  width: 0.41666667rem;
  height: 0.41666667rem;
}

.grid-area-a {
  grid-area: a;
}

.grid-area-b {
  grid-area: b;
}

.grid-area-c {
  grid-area: c;
}

.grid-area-d {
  grid-area: d;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@font-face {
  font-family: Source Han Sans;
  src: url(./SourceHanSansCN-Regular.woff2) format('TrueType');
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(./SourceHanSansCN-Regular.woff2) format('TrueType');
}

@font-face {
  font-family: Source Han Sans CN-Medium;
  src: url(./SourceHanSansCN-Regular.woff2) format('TrueType');
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url(./YouSheBiaoTiHei.woff2) format('TrueType');
}

* {
  font-family: Source Han Sans, serif;
}

.main {
  max-width: 7.5rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1500px) {
  .main {
    padding: 0 0.10416667rem;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 0.125rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  html {
    font-size: 0.125rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 0.125rem;
  }
}