@keyframes fadeIn-7ff4354e {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
.opacity-enter-active[data-v-7ff4354e] {
  animation: fadeIn-7ff4354e .3s;
}
.opacity-leave-active[data-v-7ff4354e] {
  animation: fadeIn-7ff4354e .3s reverse;
}
.relative[data-v-7ff4354e] {
  position: relative;
}
.w-full[data-v-7ff4354e] {
  width: 100%;
}
.relative-center[data-v-7ff4354e] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-7ff4354e] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-7ff4354e] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-7ff4354e] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-7ff4354e] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-7ff4354e] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-7ff4354e] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-7ff4354e] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-7ff4354e] {
  justify-content: space-between;
}
.flex[data-v-7ff4354e] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-7ff4354e] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-7ff4354e],
.card-list-no-padding .ci-description[data-v-7ff4354e] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.t-title-18[data-v-7ff4354e] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  line-height: 0.09375rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.t-value-16[data-v-7ff4354e] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #fff;
  line-height: 0.08333333rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
a[data-v-7ff4354e] {
  text-decoration: none;
}
.map-marker[data-v-7ff4354e] {
  position: relative;
}
.map-marker div[data-v-7ff4354e] {
  box-shadow: 0 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
  background-color: #fff;
  transform: translateY(calc(-0.09375rem - 100%)) translateX(-0.02083333rem);
  position: absolute;
}
.map-marker div[data-v-7ff4354e]::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.05208333rem;
  height: 0.05208333rem;
  bottom: 0;
  background-color: #fff;
  transform: rotate(45deg) translateY(0.04166667rem);
  box-shadow: 0.015625rem 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
}
.sale-ani[data-v-7ff4354e] {
  animation-name: SaleService-7ff4354e;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes SaleService-7ff4354e {
0% {
    transform: scaleX(1);
}
10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
}
30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
}
40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
}
to {
    transform: scaleX(1);
}
}
.heart-ani[data-v-7ff4354e] {
  animation-name: Heartbeat-7ff4354e;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes Heartbeat-7ff4354e {
0% {
    transform: scale(1);
}
14% {
    transform: scale(1.3);
}
28% {
    transform: scale(1);
}
42% {
    transform: scale(1.3);
}
70% {
    transform: scale(1);
}
}
.hover-scale .hover-holder[data-v-7ff4354e] {
  overflow: hidden;
}
.hover-scale .hover-target[data-v-7ff4354e] {
  transform: scale(1);
  transition: all .4s;
}
.hover-scale:hover .hover-target[data-v-7ff4354e] {
  transform: scale(1.2);
}
.get-plan-toast-shadow[data-v-7ff4354e] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.tech[data-v-7ff4354e] {
  padding: 0.41666667rem 0;
  background: url(@/asset/index/csb_index_30.png) no-repeat center/cover;
}
.tech .list[data-v-7ff4354e] {
  margin-top: 0.41666667rem;
}
.tech .list .item[data-v-7ff4354e] {
  display: flex;
  align-items: center;
  gap: 0.52083333rem;
  padding: 0.52083333rem;
  background: #fff;
}
.tech .list .item[data-v-7ff4354e]:nth-child(2n) {
  background: transparent;
  flex-direction: row-reverse;
}
.tech .list .item .info[data-v-7ff4354e] {
  flex-grow: 1;
}
.tech .list .item .info .en[data-v-7ff4354e] {
  font-size: 0.09375rem;
  color: #ddd;
}
.tech .list .item .info .title[data-v-7ff4354e] {
  font-size: 0.16666667rem;
  color: #333;
  font-weight: bold;
  margin-top: 0.04166667rem;
}
.tech .list .item .info .brief[data-v-7ff4354e] {
  font-size: 0.09375rem;
  color: #666;
  line-height: 2;
  margin-top: 0.20833333rem;
}
.tech .list .item .img[data-v-7ff4354e] {
  flex-shrink: 0;
  width: 2.39583333rem;
}
.scene[data-v-7ff4354e] {
  padding: 0.41666667rem 0;
}
.scene .tabs[data-v-7ff4354e] {
  display: flex;
  align-items: center;
  box-shadow: 0.01041667rem 0.02083333rem 0.10416667rem 0 rgba(0,0,0,.16);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  padding: 0.02083333rem;
  height: 0.3125rem;
  box-sizing: border-box;
  margin-bottom: 0.125rem;
  margin-top: 0.41666667rem;
}
.scene .tabs .item[data-v-7ff4354e] {
  border-radius: 0.04166667rem;
  color: #666;
  font-size: 0.10416667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
}
.scene .tabs .active[data-v-7ff4354e] {
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  color: #fff;
}
.scene .content[data-v-7ff4354e] {
  display: flex;
  gap: 0.125rem;
  height: 3.02083333rem;
}
.scene .content .left[data-v-7ff4354e] {
  width: 4.40104167rem;
  height: 3.02083333rem;
  flex-shrink: 0;
}
.scene .content .right[data-v-7ff4354e] {
  height: 100%;
  flex-grow: 1;
  padding: 0.125rem;
  box-shadow: 0.01041667rem 0.02083333rem 0.10416667rem 0 rgba(0,0,0,.16);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  overflow-y: scroll;
  box-sizing: border-box;
}
.scene .content .right[data-v-7ff4354e]::-webkit-scrollbar {
  width: 0.03125rem;
}
.scene .content .right[data-v-7ff4354e]::-webkit-scrollbar-thumb {
  border-radius: 0.05208333rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
}
.scene .content .right[data-v-7ff4354e]::-webkit-scrollbar-track {
  background: #e7e7e7;
}
.scene .content .right .title[data-v-7ff4354e] {
  color: #333;
  font-size: 0.14583333rem;
  line-height: 2;
}
.scene .content .right .brief[data-v-7ff4354e] {
  color: #666;
  font-size: 0.09375rem;
  line-height: 2;
  margin-bottom: 0.08333333rem;
}
.scene .content .right .sp[data-v-7ff4354e] {
  color: #096dd9;
}
.scene .content .right .head[data-v-7ff4354e] {
  line-height: 2;
  font-size: 0.07291667rem;
  color: #999;
}
.scene .content .right .number[data-v-7ff4354e] {
  line-height: 2;
  font-size: 0.07291667rem;
}
.industry[data-v-7ff4354e] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.industry .tabs[data-v-7ff4354e] {
  display: flex;
  align-items: center;
  background: linear-gradient(122deg, #ffffff 0%, #f3f8ff 100%);
  box-shadow: 0.01041667rem 0.02083333rem 0.10416667rem 0 rgba(0,0,0,.16);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  margin-bottom: 0.08333333rem;
  margin-top: 0.41666667rem;
}
.industry .tabs .item[data-v-7ff4354e] {
  font-size: 0.10416667rem;
  color: #666;
  flex: 1;
  cursor: pointer;
  height: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.industry .tabs .active[data-v-7ff4354e] {
  color: #fff;
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
}
.industry .content[data-v-7ff4354e] {
  position: relative;
  font-size: 0;
  width: 100%;
  height: 2.08333333rem;
}
.industry .content .img[data-v-7ff4354e] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.industry .content .hover[data-v-7ff4354e] {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  background: linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0.01041667rem 0.02083333rem 0.10416667rem 0 rgba(0,0,0,.16);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  padding: 0.10416667rem 0;
}
.industry .content .hover .title[data-v-7ff4354e] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.04166667rem;
  font-size: 0.14583333rem;
  color: #333;
}
.industry .content .hover .title .icon[data-v-7ff4354e] {
  width: 0.14583333rem;
}
.industry .content .hover .brief[data-v-7ff4354e] {
  color: rgba(102,102,102,.7);
  font-size: 0.09375rem;
  margin-top: 0.08333333rem;
}
.plan[data-v-7ff4354e] {
  padding: 0.41666667rem 0;
}
.plan .list[data-v-7ff4354e] {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  margin-top: 0.3125rem;
}
.plan .list .item[data-v-7ff4354e] {
  border-radius: 0.02083333rem 0.02083333rem 0.02083333rem 0.02083333rem;
  padding: 0.10416667rem 0.15625rem 0.15625rem;
  flex: 1;
  height: 1.27083333rem;
  box-sizing: border-box;
  border: 0.00520833rem solid;
  text-align: center;
  border-image: linear-gradient(180deg, #4e99fd, #5482fa) 1 1;
}
.plan .list .item .icon[data-v-7ff4354e] {
  width: 0.3125rem;
}
.plan .list .item .title[data-v-7ff4354e] {
  color: #333;
  font-size: 0.125rem;
  margin-top: 0.04166667rem;
  margin-bottom: 0.0625rem;
}
.plan .list .item .brief[data-v-7ff4354e] {
  font-size: 0.08333333rem;
  color: rgba(51,51,51,.7);
  line-height: 2;
}