@keyframes fadeIn-4e8379f8 {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
.opacity-enter-active[data-v-4e8379f8] {
  animation: fadeIn-4e8379f8 .3s;
}
.opacity-leave-active[data-v-4e8379f8] {
  animation: fadeIn-4e8379f8 .3s reverse;
}
.relative[data-v-4e8379f8] {
  position: relative;
}
.w-full[data-v-4e8379f8] {
  width: 100%;
}
.relative-center[data-v-4e8379f8] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-4e8379f8] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-4e8379f8] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-4e8379f8] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-4e8379f8] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-4e8379f8] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-4e8379f8] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-4e8379f8] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-4e8379f8] {
  justify-content: space-between;
}
.flex[data-v-4e8379f8] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-4e8379f8] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-4e8379f8],
.card-list-no-padding .ci-description[data-v-4e8379f8] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.t-title-18[data-v-4e8379f8] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  line-height: 0.09375rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.t-value-16[data-v-4e8379f8] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #fff;
  line-height: 0.08333333rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
a[data-v-4e8379f8] {
  text-decoration: none;
}
.map-marker[data-v-4e8379f8] {
  position: relative;
}
.map-marker div[data-v-4e8379f8] {
  box-shadow: 0 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
  background-color: #fff;
  transform: translateY(calc(-0.09375rem - 100%)) translateX(-0.02083333rem);
  position: absolute;
}
.map-marker div[data-v-4e8379f8]::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.05208333rem;
  height: 0.05208333rem;
  bottom: 0;
  background-color: #fff;
  transform: rotate(45deg) translateY(0.04166667rem);
  box-shadow: 0.015625rem 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
}
.sale-ani[data-v-4e8379f8] {
  animation-name: SaleService-4e8379f8;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes SaleService-4e8379f8 {
0% {
    transform: scaleX(1);
}
10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
}
30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
}
40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
}
to {
    transform: scaleX(1);
}
}
.heart-ani[data-v-4e8379f8] {
  animation-name: Heartbeat-4e8379f8;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes Heartbeat-4e8379f8 {
0% {
    transform: scale(1);
}
14% {
    transform: scale(1.3);
}
28% {
    transform: scale(1);
}
42% {
    transform: scale(1.3);
}
70% {
    transform: scale(1);
}
}
.hover-scale .hover-holder[data-v-4e8379f8] {
  overflow: hidden;
}
.hover-scale .hover-target[data-v-4e8379f8] {
  transform: scale(1);
  transition: all .4s;
}
.hover-scale:hover .hover-target[data-v-4e8379f8] {
  transform: scale(1.2);
}
.get-plan-toast-shadow[data-v-4e8379f8] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.display[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.125rem;
  padding: 0.3125rem 0 0.41666667rem;
}
.display .item[data-v-4e8379f8] {
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0 #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.08333333rem;
  line-height: 1;
  padding: 0.125rem 0;
}
.display .item .icon[data-v-4e8379f8] {
  width: 0.16666667rem;
}
.display .item .info .title[data-v-4e8379f8] {
  margin-bottom: 0.10416667rem;
  color: #333;
  font-size: 0.125rem;
}
.display .item .info .brief[data-v-4e8379f8] {
  color: #666;
  font-size: 0.07291667rem;
}
.about .top[data-v-4e8379f8] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.0625rem;
  background: url(@/asset/index/csb_index_29.png) no-repeat center/cover;
  height: 2.76041667rem;
  line-height: 1;
}
.about .top .en[data-v-4e8379f8] {
  font-size: 0.25rem;
  color: rgba(255,255,255,.4);
}
.about .top .title[data-v-4e8379f8] {
  font-size: 0.25rem;
  color: #fff;
  font-family: YouSheBiaoTiHei;
}
.about .bottom[data-v-4e8379f8] {
  background: url(@/asset/index/csb_index_27.png) no-repeat -0.15625rem 0.10416667rem/2.65625rem,url(@/asset/index/csb_index_27.png) no-repeat right -0.52083333rem bottom/2.65625rem;
  display: flex;
  padding-bottom: 0.15625rem;
}
.about .bottom .content[data-v-4e8379f8] {
  width: 5.625rem;
  margin: 0 auto;
  padding: 0.26041667rem 0.41666667rem;
  border-radius: 0.10416667rem;
  margin-top: -0.52083333rem;
  background: #fff;
  box-sizing: border-box;
}
.about .bottom .content .digit[data-v-4e8379f8] {
  display: flex;
  align-items: center;
}
.about .bottom .content .digit .item[data-v-4e8379f8] {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  gap: 0.08333333rem;
}
.about .bottom .content .digit .item:first-child~.item[data-v-4e8379f8] {
  border-left: 0.00520833rem solid #c4c4c4;
}
.about .bottom .content .digit .item .icon[data-v-4e8379f8] {
  width: 0.3125rem;
}
.about .bottom .content .digit .item .info .name[data-v-4e8379f8] {
  font-size: 0.08333333rem;
  color: #282938;
  margin-bottom: 0.05208333rem;
}
.about .bottom .content .digit .item .info .number[data-v-4e8379f8] {
  font-size: 0.1875rem;
  color: #096dd9;
}
.about .bottom .content .title[data-v-4e8379f8] {
  font-size: 0.125rem;
  color: #666;
  margin-top: 0.30208333rem;
  margin-bottom: 0.08333333rem;
}
.about .bottom .content .txt[data-v-4e8379f8] {
  font-size: 0.08333333rem;
  color: #666;
  line-height: 2;
}
.about .bottom .content .strip[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.125rem;
  color: #455a64;
  font-size: 0.27083333rem;
  background-color: rgba(196,196,196,.2);
  padding: 0.234375rem 0;
  margin-top: 0.15625rem;
}
.about .bottom .content .strip .icon[data-v-4e8379f8] {
  width: 0.25rem;
}
.tech[data-v-4e8379f8] {
  padding: 0.41666667rem 0;
  background: url(@/asset/index/csb_index_30.png) no-repeat center/cover;
}
.tech .list[data-v-4e8379f8] {
  margin-top: 0.41666667rem;
}
.tech .list .item[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  gap: 0.52083333rem;
  padding: 0.52083333rem;
  background: #fff;
}
.tech .list .item[data-v-4e8379f8]:nth-child(2n) {
  background: transparent;
  flex-direction: row-reverse;
}
.tech .list .item .info[data-v-4e8379f8] {
  flex-grow: 1;
}
.tech .list .item .info .en[data-v-4e8379f8] {
  font-size: 0.09375rem;
  color: #ddd;
}
.tech .list .item .info .title[data-v-4e8379f8] {
  font-size: 0.16666667rem;
  color: #333;
  font-weight: bold;
  margin-top: 0.04166667rem;
}
.tech .list .item .info .brief[data-v-4e8379f8] {
  font-size: 0.09375rem;
  color: #666;
  line-height: 2;
  margin-top: 0.20833333rem;
}
.tech .list .item .img[data-v-4e8379f8] {
  flex-shrink: 0;
  width: 2.39583333rem;
}
.prod-display[data-v-4e8379f8] {
  padding: 0.41666667rem 0.72916667rem 0;
}
.prod-display .prod-tabs[data-v-4e8379f8] {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.prod-display .prod-tabs .prod-item[data-v-4e8379f8] {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.125rem;
  color: #666;
  cursor: pointer;
}
.prod-display .prod-tabs .prod-item .prod-icon[data-v-4e8379f8] {
  height: 0.16666667rem;
  margin-right: 0.08333333rem;
}
.prod-display .prod-tabs .prod-item .prod-txt[data-v-4e8379f8] {
  padding: 0 0.02604167rem;
  box-sizing: border-box;
}
.prod-display .prod-tabs .prod-item[data-v-4e8379f8]:hover {
  color: #096dd9;
}
.prod-display .prod-tabs .prod-active[data-v-4e8379f8] {
  color: #096dd9;
  flex-wrap: wrap;
}
.prod-display .prod-tabs .prod-active .prod-icon[data-v-4e8379f8] {
  height: 0.41666667rem;
  margin-right: 0;
}
.prod-display .prod-tabs .prod-active .prod-txt[data-v-4e8379f8] {
  width: 100%;
  margin-top: 0.08333333rem;
}
.prod-display .prod-list[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.41666667rem;
  gap: 0.13020833rem;
}
.prod-display .prod-list .prod-item[data-v-4e8379f8] {
  width: calc(33% - 0.13020833rem);
  box-sizing: border-box;
  padding: 0.10416667rem;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 0.05208333rem 0 #eee;
  border-radius: 0.04166667rem;
}
.prod-display .prod-list .prod-item .prod-inner[data-v-4e8379f8] {
  position: relative;
}
.prod-display .prod-list .prod-item .prod-inner .prod-img[data-v-4e8379f8] {
  width: 100%;
  height: 2.29166667rem;
}
.prod-display .prod-list .prod-item .prod-inner .prod-hover[data-v-4e8379f8] {
  position: absolute;
  border-radius: 0.04166667rem;
  width: calc(100% - 0.16666667rem);
  left: 0.08333333rem;
  bottom: 0.08333333rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  transition: all .5s;
  font-size: 0.09375rem;
  color: #666;
}
.prod-display .prod-list .prod-item .prod-inner .prod-hover .prod-innerTxt[data-v-4e8379f8] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.prod-display .prod-list .prod-item .prod-inner:hover .prod-hover[data-v-4e8379f8] {
  opacity: .8;
  height: 40%;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  color: #fff;
  font-size: 0.10416667rem;
}
.prod-display .prod-list .prod-item .prod-inner:hover .prod-hover .prod-innerTxt[data-v-4e8379f8] {
  white-space: normal;
}
.product[data-v-4e8379f8] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.prod-button[data-v-4e8379f8] {
  font-size: 0.125rem;
  color: #fffdfd;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.10416667rem 0;
  margin: 0 auto;
  margin-top: 0.20833333rem;
  width: 1.13020833rem;
  cursor: pointer;
  gap: 0.0625rem;
}
.prod-button .icon[data-v-4e8379f8] {
  width: 0.15625rem;
}
.issue[data-v-4e8379f8] {
  background: url(@/asset/index/csb_index_35.png) no-repeat center/cover;
  padding: 0.41666667rem 0 0.72916667rem;
}
.issue .center[data-v-4e8379f8] {
  position: relative;
  margin: 0 auto;
  width: 4.140625rem;
  margin-top: 0.72916667rem;
}
.issue .center .bg[data-v-4e8379f8] {
  width: 100%;
}
.issue .center .item[data-v-4e8379f8] {
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.issue .center .item .title[data-v-4e8379f8] {
  font-size: 0.125rem;
  color: #333;
  line-height: 1.5;
}
.issue .center .item .brief[data-v-4e8379f8] {
  font-size: 0.07291667rem;
  color: #666;
  line-height: 2;
}
.issue .center .issue1[data-v-4e8379f8] {
  left: 0;
  top: -0.078125rem;
}
.issue .center .issue2[data-v-4e8379f8] {
  right: 0;
  top: -0.078125rem;
}
.issue .center .issue3[data-v-4e8379f8] {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.issue .center .issue4[data-v-4e8379f8] {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.issue .center .issue5[data-v-4e8379f8] {
  left: 0;
  bottom: -0.16666667rem;
}
.issue .center .issue6[data-v-4e8379f8] {
  right: 0;
  bottom: -0.16666667rem;
}
.resolution[data-v-4e8379f8] {
  padding: 0.41666667rem 0 0.52083333rem;
}
.resolution .content[data-v-4e8379f8] {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.28645833rem;
}
.resolution .left[data-v-4e8379f8] {
  width: 0.02083333rem;
  height: 100%;
  background: linear-gradient(180deg, rgba(78, 153, 253, 0.05) 0%, #4e99fd 55%, rgba(84, 130, 250, 0.05) 100%);
  position: relative;
  padding: 0.125rem 0;
}
.resolution .left .item[data-v-4e8379f8] {
  width: 2.25rem;
  margin-left: -0.08333333rem;
  line-height: 1;
  max-height: 0.16666667rem;
  overflow: hidden;
  transition: all .3s;
}
.resolution .left .item:first-child~.item[data-v-4e8379f8] {
  margin-top: 0.20833333rem;
}
.resolution .left .item .top[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  font-size: 0.10416667rem;
  color: #6f8cab;
  cursor: pointer;
}
.resolution .left .item .top .icon[data-v-4e8379f8] {
  width: 0.16666667rem;
}
.resolution .left .item .bottom[data-v-4e8379f8] {
  padding-left: 0.29166667rem;
  padding-top: 0.10416667rem;
  font-size: 0.10416667rem;
  color: #666;
  line-height: 2;
}
.resolution .left .active-item[data-v-4e8379f8] {
  transition: all .5s;
  max-height: 2.60416667rem;
}
.resolution .left .active-item .top[data-v-4e8379f8] {
  color: #096dd9;
  font-weight: bold;
}
.resolution .right[data-v-4e8379f8] {
  margin-top: 0.125rem;
  width: 3.83854167rem;
  background: linear-gradient(180deg, rgba(9, 109, 217, 0.1) 0%, rgba(9, 109, 217, 0) 100%);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  padding: 0.125rem;
  box-sizing: border-box;
}
.resolution .right .img[data-v-4e8379f8] {
  width: 100%;
}
.resolution .right .feature[data-v-4e8379f8] {
  display: flex;
  flex-wrap: wrap;
}
.resolution .right .feature .item[data-v-4e8379f8] {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.02083333rem;
  font-size: 0.125rem;
  color: #666;
  margin-top: 0.20833333rem;
}
.resolution .right .feature .item .icon[data-v-4e8379f8] {
  width: 0.125rem;
}
.combine[data-v-4e8379f8] {
  padding: 0.52083333rem 0;
}
.combine .content[data-v-4e8379f8] {
  position: relative;
  height: 2.55208333rem;
  margin-top: 0.88541667rem;
}
.combine .content .inner[data-v-4e8379f8] {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.10416667rem;
}
.combine .content .inner .list[data-v-4e8379f8] {
  box-sizing: border-box;
  margin-bottom: 0.10416667rem;
  padding: 0.3125rem 0.52083333rem;
  box-shadow: 0.02083333rem 0.02083333rem 0.10416667rem 0 rgba(0,0,0,.12);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 3.33333333rem;
  height: 2.1875rem;
  background: linear-gradient(151deg, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 100%);
  backdrop-filter: blur(0.02604167rem);
  gap: 0.20833333rem 0.41666667rem;
}
.combine .content .inner .list .item[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 0.20833333rem);
  gap: 0.02083333rem;
  font-size: 0.125rem;
  color: #333;
  padding: 0 !important;
}
.combine .content .inner .list .item .icon[data-v-4e8379f8] {
  width: 0.125rem;
}
.combine .content .inner .tabs[data-v-4e8379f8] {
  box-shadow: 0.01041667rem 0.02083333rem 0.10416667rem 0 rgba(0,0,0,.16);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.combine .content .inner .tabs .tab[data-v-4e8379f8] {
  flex: 1;
  height: 0.3125rem;
  border-radius: 0.04166667rem;
  font-size: 0.10416667rem;
  color: #666;
  width: 3.33333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.combine .content .inner .tabs .tab-active[data-v-4e8379f8] {
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  color: #fff;
  transition: all .5s;
}
.system[data-v-4e8379f8] {
  padding: 0.41666667rem 0;
}
.system .content[data-v-4e8379f8] {
  margin-top: 0.52083333rem;
}
.system .content .row[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.system .content .row:first-child~.row[data-v-4e8379f8] {
  margin-top: 0.26041667rem;
}
.system .content .row .item[data-v-4e8379f8] {
  display: flex;
  align-items: center;
  gap: 0.04166667rem;
  font-size: 0.09375rem;
  color: #333;
}
.system .content .row .item[data-v-4e8379f8]:hover {
  color: #4e99fd;
}
.system .content .row .item .icon[data-v-4e8379f8] {
  width: 0.09375rem;
}
.system .content .row1[data-v-4e8379f8],
.system .content .row6[data-v-4e8379f8] {
  width: 4.29166667rem;
}
.system .content .row2[data-v-4e8379f8],
.system .content .row5[data-v-4e8379f8] {
  width: 5.3125rem;
}
.system .content .row3[data-v-4e8379f8],
.system .content .row4[data-v-4e8379f8] {
  width: 5.78125rem;
}